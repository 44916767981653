import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const DevelopmentServices = () => {
  const { t } = useTranslation();
  const [animationComplete, setAnimationComplete] = useState(false);
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView && !animationComplete) {
      controls.start("visible");
      setAnimationComplete(true);
    } else if (!inView && animationComplete) {
      controls.start("hidden");
      setAnimationComplete(false);
    }
  }, [controls, inView, animationComplete]);

  return (
    <motion.div
    ref={ref}
    animate={controls}
    initial="hidden"
    transition={{ duration: 0.5 }} // Aumenta la duración de la transición
    variants={{
      visible: { opacity: 1, scale: 1 },
      hidden: { opacity: 0.2, scale: 0.9 }, // Reduce la escala y la opacidad inicial
    }} id="Development" className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mt-12">
    <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
      <div className="lg:order-last flex flex-col text-left justify-center">
        <div className="max-w-2xl mb-6">
          <h2 className="max-w-xl mb-6 font-motivabl text-3xl font-bold tracking-tight text-[#18216D] sm:text-[39px] sm:leading-tight">
            {t("Custom Development Services: Transforming Ideas into Digital Reality")}
          </h2>
          <p className="text-base font-motivalg text-[#18216D] md:text-lg">
            {t("Turn your unique ideas into reality with our Custom Development Services. Our talented developers craft tailored solutions to meet your specific needs, from web and mobile apps to specialized software")}
          </p>
        </div>
      </div>
      <div>
        <img
          className="object-cover lg:mt-none -mt-5 w-full h-full sm:h-full"
          src="https://img.invupos.com/HT%20Solutions/BespokeSoftware.svg"
          alt=""
        />
      </div>
    </div>
  </motion.div>
  )
}

export default DevelopmentServices;