import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const Contact = () => {
    const { t } = useTranslation();
    const [animationComplete, setAnimationComplete] = useState(false);
    const [ref, inView] = useInView();
    const controls = useAnimation();
  
    useEffect(() => {
      if (inView && !animationComplete) {
        controls.start("visible");
        setAnimationComplete(true);
      } else if (!inView && animationComplete) {
        controls.start("hidden");
        setAnimationComplete(false);
      }
    }, [controls, inView, animationComplete]);

    const submitHandler = (event) => {
      event.preventDefault();
      const fullname_field = event.target.fullname.value;
      const email_field = event.target.emailaddress.value;
      const message_field = event.target.messagedescription.value;
      const services_field = event.target.services.value;
    
      if (!fullname_field || !email_field || !message_field || !services_field) {
        toast.error("Por favor, complete todos los campos obligatorios.", {
          position: "bottom-center",
          duration: 5000,
        });
        return;
      }
    
      const data = {
        destino: ["admin@clau.io", "info@httecsolutions.com"],
        mensaje: `
            <style type="text/css">
            body,
            .background_main,
            p,
            table,
            td,
            div {
              font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
            }

            img {
              border: none;
              -ms-interpolation-mode: bicubic;
              max-width: 100%;
            }

            p {
              padding-bottom: 2px;
            }

            body {
              background: #fff;
              font-size: 17px;
              line-height: 24px;
              margin: 0;
              padding: 0;
              -ms-text-size-adjust: 100%;
              -webkit-text-size-adjust: 100%;
            }

            table {
              border-collapse: collapse;
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
              width: 100%;
            }

            td {
              font-size: 17px;
              line-height: 24px;
              vertical-align: top;
            }

            .email_footer td,
            .email_footer p,
            .email_footer span,
            .email_footer a {
              font-size: 15px;
              text-align: center;
            }

            .email_footer td {
              padding-top: 20px;
            }

            h1,
            h2,
            h3,
            h4 {
              color: #434245;
              font-weight: 400;
              line-height: 1.4;
              margin: 0;
              margin-bottom: 12px;
            }

            h1 {
              font-size: 30px;
              line-height: 36px;
              font-weight: 900;
              letter-spacing: -0.75px;
              text-align: left;
            }

            p,
            ul,
            ol {
              font-size: 17px;
              line-height: 24px;
              font-weight: normal;
              margin: 0;
              margin-bottom: 15px;
            }

            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }

            a {
              color: #3498db;
              text-decoration: none;
            }

            a:hover {
              text-decoration: underline;
            }

            .button_link::after {
              position: absolute;
              content: "";
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 0px;
            }

            .button_link:hover::after {
              box-shadow: inset 0 #fff;
            }

            .preview_text {
              color: transparent;
              display: none;
              height: 0;
              max-height: 0;
              max-width: 0;
              opacity: 0;
              overflow: hidden;
              mso-hide: all;
              visibility: hidden;
              width: 0;
              font-size: 1px;
              line-height: 1px;
            }

            .preview_text a {
              color: #3aa3e3 !important;
              font-weight: bold;
            }

            @media only screen and (max-width: 600px) {
              table[class="background_main"] .sm_full_width {
                width: 100% !important;
              }
              table[class="background_main"] .sm_align_center {
                text-align: center !important;
              }
              table[class="background_main"] .sm_auto_width {
                width: auto !important;
              }
              table[class="background_main"] .sm_auto_height {
                height: auto !important;
              }

              table[class="background_main"] .sm_border_box {
                box-sizing: border-box !important;
              }

              table[class="background_main"] .sm_block {
                display: block !important;
              }
              table[class="background_main"] .sm_inline_block {
                display: inline-block !important;
              }
              table[class="background_main"] .sm_table {
                display: table !important;
              }

              table[class="background_main"] .sm_no_side_padding {
                padding-right: 0 !important;
                padding-left: 0 !important;
              }
              table[class="background_main"] .sm_no_border_radius {
                border-radius: 0 !important;
              }
              table[class="background_main"] .sm_no_padding {
                padding-right: 0 !important;
                padding-left: 0 !important;
              }
              table[class="background_main"] .sm_os_icons_height {

                height: 44px;
              }
              .social_img_bottom_margin {

                margin-bottom: 20px !important;
              }
              .social_p_bottom_margin {

                margin-bottom: 40px !important;
              }
            }

            @media all {
              .ExternalClass {
                width: 100%;
              }
              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }
              .email_footer a {
                color: #18216d !important;
                font-family: inherit !important;
                font-size: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
                text-decoration: none !important;
              }
            }

            a:hover {
              text-decoration: underline !important;
            }
          </style>
        </head>

        <body>
          <table
            width="100%"
            cellpadding="0"
            cellspacing="0"
            border="0"
            class="background_main"
            style="
              background-color: #f3f4ff;
              padding-top: 20px;
              color: #434245;
              width: 100%;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            "
          >
            <tr>
              <td
                valign="top"
                class="sm_full_width"
                style="margin: 0 auto; width: 100%; max-width: 600px; display: block;"
              >
                <div
                  class="sm_no_padding"
                  style="
                    margin: 0 auto;
                    padding: 30px 0 40px;
                    display: block;
                    box-sizing: border-box;
                  "
                >
                  <table
                    style="width: 100%; color: #434245;"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                  >
                    <tr>
                      <td style="box-sizing: border-box;">
                        <table border="0" cellpadding="0" cellspacing="0">
                          <tr>
                            <td>
                              <img style=" margin: 0 0 20px 0; padding-right: 20px;
                              padding-left: 20px;"
                              src="https://img.invupos.com/HT%20Solutions/HT_solutions_logo.png"
                              />

                              <h1
                                style="
                                  font-size: 30px;
                                  padding-right: 30px;
                                  padding-left: 30px;
                                  margin-top: 25px;
                                "
                              >
                              Nuevo Cliente
                              </h1>

                              <p
                                style="
                                  font-size: 17px;
                                  padding-right: 30px;
                                  padding-left: 30px;
                                "
                              >
                                Información recopilada:
                              </p>

                              <p
                                style="
                                  font-size: 17px;
                                  padding-right: 30px;
                                  padding-left: 30px;
                                "
                              >
                              Nombre del cliente: <span><b>${fullname_field}</b></span>
                              </p>
                              <p
                                style="
                                  font-size: 17px;
                                  padding-right: 30px;
                                  padding-left: 30px;
                                "
                              >
                              Correo electrónico: <span><b>${email_field}</b></span>
                              </p>
                              <p
                                style="
                                  font-size: 17px;
                                  padding-right: 30px;
                                  padding-left: 30px;
                                "
                              >
                              Servicios: <span><b>${services_field}</b></span>
                              </p>
                              <p
                              style="
                                font-size: 17px;
                                padding-right: 30px;
                                padding-left: 30px;
                              "
                            >
                            Mensaje: <span><b>${message_field}</b></span>
                            </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td
                class="email_footer"
                style="
                  padding: 0 30px 40px;
                  border-top: 1px solid #e1e1e4;
                  line-height: 24px;
                  font-size: 15px;
                  color: #717274;
                  text-align: center;
                  width: 100%;
                "
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  align="center"
                  style="margin-top: 20px; background-color: #F3F4FF;"
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <span style="display: block;">
                        Powered by
                        <a href="https://httecsolutions.com/" target="_blank" style="color: #0012b2;"><b>HT Solutions</b></a>
                      </span>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </body>
        `,
        sujeto: "Ayuda en HT Solutions",
        pass: "chimichanga",
        origen: "Landing HT Solutions",
        razon: "Nuevo Cliente",
      };
    
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          APPID: 1,
          apikey: "POS-wDbrHevJ5qCzPNGRUWie481ncDW3zbAzs6dMtPzsusdRW7koFtbLde9WGS",
          origen: "quickie",
        },
      };
    
      axios
        .post("https://apidev2.clau.io/mplat_forms/app/open_mail", data, axiosConfig)
        .then((response) => {
          if (response.data.CodRes === 0) {
            toast.success("Email enviado correctamente.", {
              position: "bottom-center",
              duration: 5000,
            });
    
            const mensaje1 = `
              <style type="text/css">
              body,
              .background_main,
              p,
              table,
              td,
              div {
                font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
              }
          
              img {
                border: none;
                -ms-interpolation-mode: bicubic;
                max-width: 100%;
              }
          
              p {
                padding-bottom: 2px;
              }
          
              body {
                background: #fff;
                font-size: 17px;
                line-height: 24px;
                margin: 0;
                padding: 0;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
              }
          
              table {
                border-collapse: collapse;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                width: 100%;
              }
          
              td {
                font-size: 17px;
                line-height: 24px;
                vertical-align: top;
              }
          
              .email_footer td,
              .email_footer p,
              .email_footer span,
              .email_footer a {
                font-size: 15px;
                text-align: center;
              }
          
              .email_footer td {
                padding-top: 20px;
              }
          
              h1,
              h2,
              h3,
              h4 {
                color: #434245;
                font-weight: 400;
                line-height: 1.4;
                margin: 0;
                margin-bottom: 12px;
              }
          
              h1 {
                font-size: 30px;
                line-height: 36px;
                font-weight: 900;
                letter-spacing: -0.75px;
                text-align: left;
              }
          
              p,
              ul,
              ol {
                font-size: 17px;
                line-height: 24px;
                font-weight: normal;
                margin: 0;
                margin-bottom: 15px;
              }
          
              p li,
              ul li,
              ol li {
                list-style-position: inside;
                margin-left: 5px;
              }
          
              a {
                color: #3498db;
                text-decoration: none;
              }
          
              a:hover {
                text-decoration: underline;
              }
          
              .button_link::after {
                position: absolute;
                content: "";
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 0px;
              }
          
              .button_link:hover::after {
                box-shadow: inset 0 #fff;
              }
          
              .preview_text {
                color: transparent;
                display: none;
                height: 0;
                max-height: 0;
                max-width: 0;
                opacity: 0;
                overflow: hidden;
                mso-hide: all;
                visibility: hidden;
                width: 0;
                font-size: 1px;
                line-height: 1px;
              }
          
              .preview_text a {
                color: #3aa3e3 !important;
                font-weight: bold;
              }
          
              @media only screen and (max-width: 600px) {
                table[class="background_main"] .sm_full_width {
                  width: 100% !important;
                }
                table[class="background_main"] .sm_align_center {
                  text-align: center !important;
                }
                table[class="background_main"] .sm_auto_width {
                  width: auto !important;
                }
                table[class="background_main"] .sm_auto_height {
                  height: auto !important;
                }
          
                table[class="background_main"] .sm_border_box {
                  box-sizing: border-box !important;
                }
          
                table[class="background_main"] .sm_block {
                  display: block !important;
                }
                table[class="background_main"] .sm_inline_block {
                  display: inline-block !important;
                }
                table[class="background_main"] .sm_table {
                  display: table !important;
                }
          
                table[class="background_main"] .sm_no_side_padding {
                  padding-right: 0 !important;
                  padding-left: 0 !important;
                }
                table[class="background_main"] .sm_no_border_radius {
                  border-radius: 0 !important;
                }
                table[class="background_main"] .sm_no_padding {
                  padding-right: 0 !important;
                  padding-left: 0 !important;
                }
                table[class="background_main"] .sm_os_icons_height {
          
                  height: 44px;
                }
                .social_img_bottom_margin {
          
                  margin-bottom: 20px !important;
                }
                .social_p_bottom_margin {
          
                  margin-bottom: 40px !important;
                }
              }
          
              @media all {
                .ExternalClass {
                  width: 100%;
                }
                .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                  line-height: 100%;
                }
                .email_footer a {
                  color: #18216d !important;
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  text-decoration: none !important;
                }
              }
          
              a:hover {
                text-decoration: underline !important;
              }
            </style>
          </head>
          
          <body>
            <table
              width="100%"
              cellpadding="0"
              cellspacing="0"
              border="0"
              class="background_main"
              style="
                background-color: #f3f4ff;
                padding-top: 20px;
                color: #434245;
                width: 100%;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              "
            >
              <tr>
                <td
                  valign="top"
                  class="sm_full_width"
                  style="margin: 0 auto; width: 100%; max-width: 600px; display: block;"
                >
                  <div
                    class="sm_no_padding"
                    style="
                      margin: 0 auto;
                      padding: 30px 0 40px;
                      display: block;
                      box-sizing: border-box;
                    "
                  >
                    <table
                      style="width: 100%; color: #434245;"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tr>
                        <td style="box-sizing: border-box;">
                          <table border="0" cellpadding="0" cellspacing="0">
                            <tr>
                              <td>
                                <img style=" margin: 0 0 20px 0; padding-right: 20px;
                                padding-left: 20px;"
                                src="https://img.invupos.com/HT%20Solutions/HT_solutions_logo.png"
                                />
          
                                <h1
                                  style="
                                    font-size: 30px;
                                    padding-right: 30px;
                                    padding-left: 30px;
                                    margin-top: 25px;
                                  "
                                >
                                Hola, ${fullname_field}
                                </h1>
          
                                <p
                                  style="
                                    font-size: 17px;
                                    padding-right: 30px;
                                    padding-left: 30px;
                                  "
                                >
                                  Bienvenido a HT Solutions,
                                </p>
          
                                <p
                                  style="
                                    font-size: 17px;
                                    padding-right: 30px;
                                    padding-left: 30px;
                                  "
                                >
                                ¡Gracias por completar nuestro formulario! Tus datos se han registrado con éxito, y pronto nos comunicaremos contigo. 
                                Agradecemos tu participación.
                                </p>
                                <p
                                  style="
                                    font-size: 17px;
                                    padding-right: 30px;
                                    padding-left: 30px;
                                  "
                                >
                                  Para más información:
                                </p>
          
                                <div style="padding-right: 30px; padding-left: 30px;">
                                  <table style="width: 100%;" class="sm_table">
                                    <tr style="width: 100%;">
                                      <td style="width: 100%;">
                                        <span
                                          style="
                                            display: inline-block;
                                            position: relative;
                                          "
                                          class="sm_full_width"
                                        >
                                          <a
                                            class="button_link sm_full_width sm_border_box"
                                            target="_blank"
                                            href="https://httecsolutions.com/"
                                            style="
                                              min-width: 196px;
                                              border-top: 13px solid;
                                              border-bottom: 13px solid;
                                              border-right: 24px solid;
                                              border-left: 24px solid;
                                              border-color: #FE5B39;
                                              background-color: #FE5B39;
                                              color: #ffffff;
                                              font-size: 18px;
                                              line-height: 18px;
                                              word-break: break-word;
                                              display: inline-block;
                                              text-align: center;
                                              font-weight: 900;
                                              text-decoration: none !important;
                                            "
                                          >
                                            Nuestro sitio web
                                          </a>
                                        </span>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  class="email_footer"
                  style="
                    padding: 0 30px 40px;
                    border-top: 1px solid #e1e1e4;
                    line-height: 24px;
                    font-size: 15px;
                    color: #717274;
                    text-align: center;
                    width: 100%;
                  "
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    align="center"
                    style="margin-top: 20px; background-color: #F3F4FF;"
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <span style="display: block;">
                          Powered by
                          <a href="https://httecsolutions.com/" target="_blank" style="color: #0012b2;"><b>HT Solutions</b></a>
                        </span>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </body>        
            `;
    
            const data1 = {
              destino: [email_field],
              mensaje: mensaje1,
              sujeto: "Bienvenido HT Solutions",
              pass: "chimichanga",
              origen: "Landing HT Solutions",
              razon: "Nuevo Cliente",
            };
    
            axios
              .post(
                "https://apidev2.clau.io/mplat_forms/app/open_mail",
                data1,
                axiosConfig
              )
              .then((response) => {
                if (response.data.CodRes === 0) {
                  console.log("Mensaje de agradecimiento enviado correctamente.");
                }
              })
              .catch((error) => {
                console.error(error);
              });
    
            event.target.reset();
            const servicesSelect = document.getElementById("services");
            if (servicesSelect) {
              servicesSelect.value = ""; 
            }
          } else {
            toast.error("Error al enviar el email.", {
              position: "bottom-center",
              duration: 5000,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    
  return (
<>
<motion.div 
ref={ref}
animate={controls}
initial="hidden"
transition={{ duration: 0.5 }} // Aumenta la duración de la transición
variants={{
  visible: { opacity: 1, scale: 1 },
  hidden: { opacity: 0.2, scale: 0.9 }, // Reduce la escala y la opacidad inicial
}}
class="bg-white">
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mt-12">
        <div class="lg:flex lg:items-center lg:-mx-6">
            <div class="lg:w-1/2 lg:mx-6 text-left">
                <h2 className="max-w-xl mb-6 font-motivabl text-3xl font-bold tracking-tight text-[#18216D] sm:text-[39px] sm:leading-tight">
                    {t("Connect with HT Solutions: Your journey towards unparalleled tech solutions begins here")}
                </h2>
                <p className="max-w-xl mb-4 text-base font-motivalg text-[#18216D] md:text-lg">
                    {t("Our contact form is your gateway to a world of possibilities. Reach out to us, and together, we'll unlock innovation, streamline your operations, and accelerate your success. Don't hesitate; your next big breakthrough is just a message away. Get in touch now!")}
                </p>
            </div>

            <div class="mt-8 lg:w-1/2 lg:mx-6">
                <div
                    class="w-full px-8 py-10 mx-auto overflow-hidden bg-white shadow-xl lg:max-w-xl">
                    <form 
                        class="mt-6"
                        name="sentMessage"
                        id="contactForm"
                        onSubmit={submitHandler}
                        >
                        <div class="flex-1">
                            <label class="block mb-2 text-sm font-motivalg font-semibold text-[#18216D] text-left">{t("Full Name")}</label>
                            <input 
                                type="text" 
                                placeholder="John Doe" 
                                class="block w-full px-5 py-3 mt-2 font-motivalg text-gray-700 placeholder-gray-400 bg-white border border-gray-200 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                required
                                id="fullname"
                                name="fullname"
                                />
                        </div>

                        <div class="flex-1 mt-6">
                            <label class="block mb-2 text-sm font-motivalg font-semibold text-[#18216D] text-left">{t("Email Address")}</label>
                            <input 
                                type="email" 
                                placeholder="johndoe@example.com" 
                                class="block w-full px-5 py-3 mt-2 font-motivalg text-gray-700 placeholder-gray-400 bg-white border border-gray-200 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" 
                                required
                                id="emailaddress"
                                name="emailaddress"
                                />
                        </div>

                        <div class="w-full mt-6">
                          <label class="block mb-2 text-sm font-motivalg font-semibold text-[#18216D] text-left">{t("Services")}</label>
                          <select 
                            placeholder='Seleccionar Opciones' 
                            class="block w-full px-5 py-3 mt-2 font-motivalg text-gray-700 placeholder-gray-400 bg-white border border-gray-200 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                            required
                            id="services"
                            name="services" 
                            >
                            <option value="" disabled selected>-- {t("Select option")} --</option>
                            <option value="Tech Law">{t("Tech Law")}</option>
                            <option value="AWS Managment">{t("AWS Management")}</option>
                            <option value="Development">{t("Development")}</option>
                          </select>
                        </div>

                        <div class="w-full mt-6">
                            <label class="block mb-2 text-sm font-motivalg font-semibold text-[#18216D] text-left">{t("Message")}</label>
                            <textarea 
                                class="block w-full h-32 px-5 py-3 mt-2 font-motivalg text-gray-700 placeholder-gray-400 bg-white border border-gray-200 md:h-48 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" 
                                placeholder={t("Enter your message")}
                                required
                                id="messagedescription"
                                name="messagedescription"
                                >
                                </textarea>
                        </div>

                        <div className='text-right'>
                        <button 
                            type="submit"
                            id="sendMessageButton"
                            class="inline-block font-motivabl mt-7 px-16 py-3.5 text-base font-medium text-white  transition duration-500 ease-in-out shadow-lg shadow-[#948ab4] hover:shadow-lg hover:shadow-[#f9c4b6] focus:outline-none focus:ring bg-[#2e186a] hover:bg-[#ff825c]">
                            {t("Submit Contact")}
                        </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</motion.div>
      <Toaster position="bottom-center" reverseOrder={false} />
      </>

        
  )
}

export default Contact
