import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

// Modal Contact
import ModalContact from "../../ModalContact/index";

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [animationComplete, setAnimationComplete] = useState(false);
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView && !animationComplete) {
      controls.start("visible");
      setAnimationComplete(true);
    } else if (!inView && animationComplete) {
      controls.start("hidden");
      setAnimationComplete(false);
    }
  }, [controls, inView, animationComplete]);

  return (
    <>
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.5 }} // Aumenta la duración de la transición
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0.2, scale: 0.9 }, // Reduce la escala y la opacidad inicial
        }}
        name="hero"
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-36 mt-12"
      >
        <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col text-left justify-center">
            <div className="max-w-2xl mb-6">
              <h1 className="max-w-xl mb-6 font-motivabl text-3xl font-bold tracking-tight text-[#18216D] sm:text-[39px] sm:leading-tight">
                {t("AWS Server Management, Tech Talent Recruitment and Custom Development Services")}
              </h1>
              <p className="text-base font-motivalg text-[#18216D] md:text-lg">
                {t("We're your partners in technology excellence. We bring innovation to life, offering a comprehensive suite of services designed to empower your business and help you thrive in the digital age")}
              </p>
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="inline-block font-motivabl mt-7 px-16 py-3.5 text-base font-medium text-white transition duration-500 ease-in-out shadow-lg shadow-[#948ab4] hover:shadow-lg hover:shadow-[#f9c4b6] focus:outline-none focus:ring bg-[#2e186a] hover:bg-[#ff825c]"
              >
                {t("Contact")}
              </button>
            </div>
          </div>
          <div>
            <img
              className="object-cover lg:mt-none -mt-5 w-full h-full sm:h-full"
              src="https://img.invupos.com/HT%20Solutions/hero/Hero%20image%20illustration.svg"
              alt=""
            />
          </div>
        </div>
      </motion.div>
      {isOpen && <ModalContact setIsOpen={setIsOpen} />}
    </>
  );
};

export default Hero;
