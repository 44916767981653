import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import ModalContact from "../../ModalContact";

const WhyChooseUs = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const [animationComplete, setAnimationComplete] = useState(false);
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView && !animationComplete) {
      controls.start("visible");
      setAnimationComplete(true);
    } else if (!inView && animationComplete) {
      controls.start("hidden");
      setAnimationComplete(false);
    }
  }, [controls, inView, animationComplete]);


  return (
    <>
    <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.5 }} // Aumenta la duración de la transición
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0.2, scale: 0.9 }, // Reduce la escala y la opacidad inicial
        }} className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-0">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <h2 className="max-w-lg mb-6 text-[#18216D] font-motivabl text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto">
        {t("Why Choose Us")}
      </h2>
      <p className="text-base font-motivalg text-[#18216D] md:text-lg">
      {t("In a world full of choices, choosing us means opting for collaboration, excellence and leadership")}
      </p>
    </div>
    <div className="grid gap-8 text-left row-gap-5 md:row-gap-8 lg:grid-cols-4">
      <div className="p-5 duration-300 transform bg-white border-2 border-dashed rounded shadow-sm border-[#ffbfa9] hover:-translate-y-2">
        <div className="flex items-center mb-2">
          <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-[#FE7624]">
            1
          </p>
          <p className="text-lg text-[#18216D] font-motivabl font-bold leading-5">{t("Expertise")}</p>
        </div>
        <p className="text-md text-[#18216D] font-motivalg">
          {t("Our team consists of industry experts who are passionate about technology and dedicated to your success")}
        </p>
      </div>
      <div className="p-5 duration-300 transform bg-white border-2 border-dashed rounded shadow-sm border-[#ffbfa9] hover:-translate-y-2">
        <div className="flex items-center mb-2">
          <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-[#FE7624]">
            2
          </p>
          <p className="text-lg text-[#18216D] font-motivabl font-bold leading-5">{t("Innovation")}</p>
        </div>
        <p className="text-md text-[#18216D] font-motivalg">
          {t("We stay at the forefront of tech trends, ensuring your solutions are cutting-edge and future-proof")}
        </p>
      </div>
      <div className="p-5 duration-300 transform bg-white border-2 border-dashed rounded shadow-sm border-[#ffbfa9] hover:-translate-y-2">
        <div className="flex items-center mb-2">
          <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-[#FE7624]">
            3
          </p>
          <p className="text-lg text-[#18216D] font-motivabl font-bold leading-5">{t("Collaboration")}</p>
        </div>
        <p className="text-md text-[#18216D] font-motivalg">
          {t("We believe in the power of collaboration and work closely with you to understand your unique needs")}
        </p>
      </div>
      <div className="relative p-5 duration-300 transform bg-white border-2 rounded shadow-sm border-[#fe5b39] hover:-translate-y-2">
        <div className="flex items-center mb-2">
          <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-[#FE7624]">
            4
          </p>
          <p className="text-lg text-[#18216D] font-motivabl font-bold leading-5">{t("Results")}</p>
        </div>
        <p className="text-md text-[#18216D] font-motivalg">
            {t("Our focus is on delivering measurable results that drive your business forward")}
        </p>
        <p className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 -mt-4 -mr-4 font-bold rounded-full bg-[#fe5b39] sm:-mt-5 sm:-mr-5 sm:w-10 sm:h-10">
          <svg
            className="text-white w-7"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <polyline
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="6,12 10,16 18,8"
            />
          </svg>
        </p>
      </div>
    </div>
    <div className='mt-8'>
        <button   
        onClick={() => {setIsOpen(true)}} 
        className="inline-block font-motivabl font-bold px-16 py-3.5 text-base  text-white  transition duration-500 ease-in-out shadow-lg shadow-[#948ab4] hover:shadow-lg hover:shadow-[#f9c4b6] focus:outline-none focus:ring bg-[#2e186a] hover:bg-[#ff825c]">
            {t("Contact")}
        </button>
    </div>
  </motion.div>
  {isOpen && <ModalContact setIsOpen={setIsOpen} />}
  </>
  )
}

export default WhyChooseUs;