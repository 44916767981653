import "./App.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "./components/LanguageSwitcher";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { removeLngPrefix } from "./i18n";
import Landing from "./pages/Landing";

function App() {
  const [show, setShow] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(
    localStorage.getItem("languageSelected") || "es"
  );

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 0);
  }, []);

  const {
    t,
    i18n,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const currentPathname = window.location.href.replace(
      window.location.origin,
      ""
    );

    const newPathname = `/${language}${removeLngPrefix(currentPathname)}`;

    if (currentPathname !== newPathname) {
      window.history.replaceState({}, "", newPathname);
    }
  }, [language, i18n]);

  useEffect(() => {
    localStorage.setItem("languageSelected", languageSelected);
  }, [languageSelected]);

  if (!show) return null;

  return (
    <Router basename={`/${language}`}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;
