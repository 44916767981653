import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from '../../LanguageSwitcher';

import { Link } from "react-scroll";

// Modal Contact
import ModalContact from '../../ModalContact';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <>
    <header className="bg-white">
    <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div className="flex lg:flex-1">
        <a href="#" className="-m-1.5 p-1.5">
          <span className="sr-only">HT Solutions</span>
          <img className="h-8 w-auto" src="https://img.invupos.com/HT%20Solutions/Navbar/HT_solutions_logo.svg" alt="HT Solutions" />
        </a>
      </div>
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <Popover.Group className="hidden lg:flex lg:gap-x-12">
        <Link 
         to="Techlaw"
         activeClass="active"
         spy={true}
         smooth={true}
        className="cursor-pointer text-base font-motivalg font-semibold leading-6 text-[#2e186a] transition duration-300 ease-in-out hover:text-[#ff825c]">
          {t("Tech Law")}
        </Link>

        <Link 
         to="AWSManagement"
         activeClass="active"
         spy={true}
         smooth={true}  
         className="cursor-pointer text-base font-motivalg font-semibold leading-6 text-[#2e186a] transition duration-300 ease-in-out hover:text-[#ff825c]">
          {t("AWS Management")}
        </Link>

        <Link
         to="Development"
         activeClass="active"
         spy={true}
         smooth={true}
         className="cursor-pointer text-base font-motivalg font-semibold  leading-6 text-[#2e186a] transition duration-300 ease-in-out hover:text-[#ff825c]">
          {t("Development")}
        </Link>
      </Popover.Group>
      <div className="hidden lg:flex lg:flex-1 lg:justify-end">
        <button onClick={() => setIsOpen(true)} className="inline-block font-motivabl font-bold  px-10 py-3 text-base  text-white  transition duration-500 ease-in-out shadow-lg shadow-[#948ab4] hover:shadow-lg hover:shadow-[#f9c4b6] focus:outline-none focus:ring bg-[#2e186a] hover:bg-[#ff825c]">
          {t("Contact")}
        </button>
      </div>
    </nav>
    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
      <div className="fixed inset-0 z-10" />
      <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div className="flex items-center justify-between">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">HT Solutions</span>
            <img
              className="h-8 w-auto"
              src="https://img.invupos.com/HT%20Solutions/Navbar/HT_solutions_logo.svg"
              alt="HT Solutions logo"
            />
          </a>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
            <Link 
              to="Techlaw"
              activeClass="active"
              spy={true}
              smooth={true}
              >
                <button onClick={() => setMobileMenuOpen(false)} className="-mx-3 block font-motivalg font-bold rounded-lg px-3 py-2 text-base leading-7 text-[#2e186a] transition duration-300 ease-in-out hover:text-[#ff825c] hover:bg-gray-50 w-full text-left">
                {t("Tech Law")}
                </button>
              </Link>
              
              <Link 
              to="AWSManagement"
              activeClass="active"
              spy={true}
              smooth={true}
              >
                 <button onClick={() => setMobileMenuOpen(false)} className="-mx-3 block font-motivalg font-bold rounded-lg px-3 py-2 text-base leading-7 text-[#2e186a] transition duration-300 ease-in-out hover:text-[#ff825c] hover:bg-gray-50 w-full text-left">
                 {t("AWS Management")}
                 </button>
              </Link>

              <Link 
              to="Development"
              activeClass="active"
              spy={true}
              smooth={true}
              >
                <button onClick={() => setMobileMenuOpen(false)} className="-mx-3 block font-motivalg font-bold rounded-lg px-3 py-2 text-base leading-7 text-[#2e186a] transition duration-300 ease-in-out hover:text-[#ff825c] hover:bg-gray-50 w-full text-left">
                {t("Development")}
                </button>
              </Link>
            </div>
            <div className="py-6">
              <button
                 onClick={() => {
                   setMobileMenuOpen(false);
                   setIsOpen(true);
                }}
                className="block font-motivabl text-center px-3 py-2.5 text-base leading-7 text-base font-medium text-white  transition duration-500 ease-in-out shadow-lg shadow-[#948ab4] hover:shadow-lg hover:shadow-[#f9c4b6] focus:outline-none focus:ring bg-[#2e186a] hover:bg-[#ff825c] w-full"
              >
                {t("Contact")}
              </button>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  </header>
      {isOpen && <ModalContact setIsOpen={setIsOpen} />}
      </>
  );
};

export default Navbar;
